import React from "react";
import fontendImg from "../../assets/images/front-end.png";
import backendImg from "../../assets/images/backend.png";
import uiImg from "../../assets/images/design.png";
import appsImg from "../../assets/images/apps.png";

const Service = () => {
  return (
    <section id="services">
      <div className="container lg:pt-5">
        <div className="text-center">
          <h2 className=" text-center text-headingColor font-[800] text-[40px] mb-5">
            What do I help
          </h2>
          <p className="lg:max-w-[600px] lg:mx-auto text-headingColor font-[500] text-[16px] leading-7">
            As a frontend web developer, you help create engaging and
            user-friendly interfaces that enhance the user experience and make
            navigating the website or application easier and more intuitive.
          </p>
        </div>

        <div className="flex flex-col justify-center sm:py-12">
          <div className=" w-full py-3 px-2 sm:max-w-xl sm:mx-auto sm-px-0">
            <div className=" relative text-gray-700 antialiased text-sm font-semibold ">
              {/* ========== vartical line running throw the middle========== */}

              <div className=" hidden absolute w-1 sm:block bg-indigo-300 h-full left-1/2 transform-translate-x-1/2"></div>

              {/* ========== Left card ========== */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-start w-full mx-aut items-center">
                    <div className="w-full sm:w-1/2 sm:pr-8">
                      <div
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        className=" bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150 "
                      >
                        <h3 className=" text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                          Frontend Development{" "}
                        </h3>

                        <p className=" text-[15px] text-smallTextColor group-hover:text-white  group-hover:font-[500] leading-6">
                          Frontend development focuses on creating the
                          user-facing aspect of a website.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className=" rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                    <figure>
                      <img src={fontendImg} alt="" />
                    </figure>
                  </div>
                </div>
              </div>

              {/* =========== Righr Card ========== */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-end w-full mx-aut items-center">
                    <div className="w-full sm:w-1/2 sm:pl-8">
                      <div
                        data-aos="fade-left"
                        data-aos-delay="50"
                        data-aos-duration="1300"
                        className=" bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150 "
                      >
                        <h3 className=" text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                          Backend Development
                        </h3>

                        <p className=" text-[15px] text-smallTextColor group-hover:text-white  group-hover:font-[500] leading-6">
                          Backend development is crucial for creating dynamic
                          and interactive web applications.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className=" rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                    <figure>
                      <img src={backendImg} alt="" />
                    </figure>
                  </div>
                </div>
              </div>

              {/* ========== Left card ========== */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-start w-full mx-aut items-center">
                    <div className="w-full sm:w-1/2 sm:pr-8">
                      <div
                        data-aos="fade-right"
                        data-aos-delay="100"
                        data-aos-duration="1400"
                        className=" bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150 "
                      >
                        <h3 className=" text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                          UI/UX Design
                        </h3>

                        <p className=" text-[15px] text-smallTextColor group-hover:text-white  group-hover:font-[500] leading-6">
                          UX/UI design is essential for creating engaging and
                          user-friendly web experiences.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className=" rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                    <figure>
                      <img src={uiImg} alt="" />
                    </figure>
                  </div>
                </div>
              </div>

              {/* =========== Righr Card ========== */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-end w-full mx-aut items-center">
                    <div className="w-full sm:w-1/2 sm:pl-8">
                      <div
                        data-aos="fade-left"
                        data-aos-duration="1500"
                        className=" bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150 "
                      >
                        <h3 className=" text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                          Apps Development
                        </h3>

                        <p className=" text-[15px] text-smallTextColor group-hover:text-white  group-hover:font-[500] leading-6">
                          App development involves creating software for mobile
                          or web-based platforms.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className=" rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                    <figure>
                      <img src={appsImg} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;
