import React, { useRef, useEffect, useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";

const Header = () => {
  // toggle munu func //
  const [menuOpened, setMenuOpened] = useState(false);
  const getMenuStyle = (menuOpened) => {
    if (document.documentElement.clientWidth <= 800) {
      return { top: !menuOpened && "-135%" };
    }
  };
  // toggle munu func //

  // Sticky Header Function //
  const headerRef = useRef(null);
  const menuRef = useRef(null);
  const stickyHeaderFunc = () => {
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        headerRef.current.classList.add("sticky-header");
      } else {
        headerRef.current.classList.remove("sticky-header");
      }
    });
  };

  useEffect(() => {
    stickyHeaderFunc();
    return window.removeEventListener("scroll", stickyHeaderFunc);
  }, []);

  // Menu Click Handler //
  const handleClick = (e) => {
    e.preventDefault();

    const targetAttr = e.target.getAttribute("href");
    const location = document.querySelector(targetAttr).offsetTop;

    window.scrollTo({
      top: location - 80,
      left: 0,
    });
  };

  //   const toggleMenu = () => menuRef.current.classList.toggle("show-menu");

  return (
    <header
      ref={headerRef}
      className=" w-full h-[80px] leading-[80px] flex items-center"
    >
      <div className="container">
        <div className="flex items-center justify-between">
          {/*=========== logo start ============ */}
          <div className=" flex items-center gap-[10px]">
            <span className="w-[35px] h-[35px] bg-primaryColor text-white text-[18px] font-[500] rounded-full flex items-center justify-center ">
              P
            </span>

            <div className=" leading-[20px]">
              <h2 className=" text-xl text-smallTextColor font-[700] ">
                Ponkoj
              </h2>
              <p className=" text-smallTextColor text-[14px] font-[500]">
                personal
              </p>
            </div>
          </div>
          {/* =========== logo end ============ */}
          {/* =========== Menu start ============ */}

          <OutsideClickHandler
            onOutsideClick={() => {
              setMenuOpened(false);
            }}
          >
            <div
              className="menu h-menu"
              style={getMenuStyle(menuOpened)}
              ref={menuRef}
            >
              <ul className="flex items-center gap-10 ">
                <li>
                  <a
                    onClick={handleClick}
                    className="  text-smallTextColor font-[600]"
                    href="#about"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    onClick={handleClick}
                    className=" text-smallTextColor font-[600]"
                    href="#services"
                  >
                    Services
                  </a>
                </li>

                <li>
                  <a
                    onClick={handleClick}
                    className="  text-smallTextColor font-[600]"
                    href="#portfolio"
                  >
                    Portfolio
                  </a>
                </li>
                <li>
                  <a
                    onClick={handleClick}
                    className="  text-smallTextColor font-[600]"
                    href="#contact"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </OutsideClickHandler>

          {/* =========== Menu end ============ */}
          {/* =========== Menu right start ============ */}
          <div className="flex items-center gap-4">
            <button className=" flex items-center gap-2 text-smallTextColor font-[600] border border-solid border-smallTextColor py-2 px-4 rounded-[8px] max-h-[40px] hover:bg-smallTextColor hover:text-white hover:font-[500] ease-in duration-300">
              <i className="ri-send-plane-line"></i> Let's Talk
            </button>
            {/* <span
              onClick={toggleMenu}
              className=" text-2xl text-smallTextColor md:hidden cursor-pointer"
            >
              <i className="ri-menu-3-line"></i>
            </span> */}

            <div
              className="menu-icon"
              onClick={() => setMenuOpened((prev) => !prev)}
            >
              <BiMenuAltRight size={30} />
            </div>
          </div>
          {/* =========== Menu right end ============ */}
        </div>
      </div>
    </header>
  );
};

export default Header;
